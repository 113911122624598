$font-primary: 'Inter', sans-serif;
$statuses-new: #00D081;
$border-color: #DDE1E8;
$color-dark: #1B1D1D;
$color-grey: #7E8787;
$color-light: #A9B6B6;
$color: #000;
$color-bgr: #f3f3f3;
$color-bgr-disabled: rgba(#1B1D1D, .1);
$color-blue: #0E4DA4;
$color-disabled: #B1B4B4;
$color-error: #EB2A50;
$color-red: #f94341;
$color-green: #0EA441;

$basic: #0EA441;
$standard: #0E4DA4;
$premium: #EE9A0C;

$link-font-color: $color-blue;

$breakpoints: (
		xs: 640px,
		sm: 1024px,
		md: 1440px,
		lg: 1440px,
		xl: 1880px,
) !default;

@mixin media-breakpoint-down($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (max-width: map-get($breakpoints, $breakpoint) - .02) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}

@mixin media-breakpoint-up($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (min-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}


@function breakpoint-next($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $breakpoints) {
	$next: breakpoint-next($name, $breakpoints);
	@return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

@mixin media-breakpoint-only($name, $breakpoints: $breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$max: breakpoint-max($name, $breakpoints);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($name, $breakpoints) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}

.App {
	background-color: $color-bgr;

	&.is-loading, &.signed-out {
		display: flex;
		width: 100%;
		min-height: 100vh;
		align-items: center;
		justify-content: center;
	}

	&.signed-out {
	}
}
