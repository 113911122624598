.table {
	width: 100%;
	max-width: 100%;
	background: #fff;
	border-collapse: collapse;
	overflow: hidden;

	th, td {
		&:first-child {
			padding-left: 30px;
		}
		&:last-child {
			padding-right: 30px;
		}
	}

	&__head {
		background-color: $color-bgr;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
	}

	&__body {

		tr {
			//border-left: 40px solid transparent;
			//border-right: 40px solid transparent;
			position: relative;
			background: #fff;
			transition: background-color .2s, border-left-color .2s, border-right-color .2s;

			&:hover {
				background-color: #f3f6fa;
				//border-left-color: #f3f6fa;
				//border-right-color: #f3f6fa;
			}
		}

		td {
			padding-top: 16px;
			padding-bottom: 16px;
			border-bottom: 1px solid rgba(#000,.1);
		}

		tr:last-child {
			td {
				border-bottom: 0;
			}
		}
	}

	&__th {
		min-height: 80px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__name, &__email {
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #676F6F;

		@media (max-width: 1650px) {
			font-size: 14px;
		}
	}

	&__subs {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #676F6F;



		@media (max-width: 1650px) {
			font-size: 12px;
		}
	}

	&__name {
		&__wrapper {
			display: flex;
			align-items: center;

			.Avatar {
				margin-right: 42px;
			}
		}
	}
}

@media (max-width: 1020px) {
	.table-outer {
		width: 100%;
		overflow-x: auto;

		.table {
			width: 1020px;
		}
	}
}

.empty-table {
	min-height: 100px;
	padding: 16px 0;
	width: 100%;
	text-align: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #676F6F;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
}
