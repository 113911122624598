.api-error {
	background: #fff;
	border-radius: 12px;
	padding: 14px 20px;
	font-size: 10px;
	line-height: 12px;
	font-weight: normal;
	color: #EB2A50;
	margin: 0;
	position: relative;
	overflow: hidden;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(235, 42, 80, 0.1);
		z-index: -1;
	}
}
