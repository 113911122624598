.loading-wrapper {
	height: 300px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.checkbox {
	display: block;
	position: absolute;
	left: -5000px;
	width: 0.1px;
	height: 0.1px;

	&-span {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: #676F6F;
		position: relative;
		display: block;
		padding-left: 30px;
		min-height: 18px;

		&:before, &:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 18px;
			height: 18px;
		}

		&:before {
			border: 2px solid #676E6E;
			border-radius: 2px;
			transition: background-color .2s, border .2s;
		}

		&:after {
			background: url(/images/check.svg) no-repeat;
			background-size: 14px 11px;
			background-position: center;
			opacity: 0;
			transition: opacity .2s;
		}
	}

	&:checked + &-span {
		&:before {
			background: $color-green;
			border-color: $color-green;
		}
		&:after {
			opacity: 1;
		}
	}
}

.checkbox-button, .fake-inactive-checkbox {
	background: transparent;
	width: 18px;
	height: 18px;
	border: 2px solid #676E6E;
	border-radius: 2px;
	transition: background-color .2s, border .2s;

	&.active {
		background: url(/images/check.svg) no-repeat;
		background-size: 14px 11px;
		background-position: center;
		background-color: $color-green;
		border-color: $color-green;
	}
}

.tooltip {
	background: $color-dark;
	box-shadow: 0px 4px 30px rgba(122, 125, 137, 0.05);
	border-radius: 2px;
	width: 353px;
	max-width: 100vw;
	position: absolute;
	bottom: 100%;
	margin-bottom: 20px;
	right: 0;
	padding: 16px 22px;
	font-size: 12px;
	line-height: 15px;
	color: #fff;
	font-weight: 400;
	transition: opacity .2s;

	&:before {
		content: '';
		position: absolute;
		top: 100%;
		right: 30px;
		border-top: 7px solid $color-dark;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
	}
}

.fake-inactive-checkbox {
	background: #676E6E;
	display: block;
	background: url(/images/check.svg) no-repeat;
	background-size: 14px 11px;
	background-position: center;
	background-color: #676E6E;
}

.licence-type {
	&-standard {
		color: $standard;
	}
	&-premium {
		color: $premium;
	}
}

.wrapper {
	width: 1280px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;

	@media (max-width: 1320px) {
		width: 992px;
	}

	@media (max-width: 1020px) {
		width: 100%;
		padding-left: 16px;
		padding-right: 16px;
	}
}
