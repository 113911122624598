//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-Light.eot');
	src: url('../../fonts/Inter-Light.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-Light.woff2') format('woff2'),
	url('../../fonts/Inter-Light.woff') format('woff'),
	url('../../fonts/Inter-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-ExtraLightItalic.eot');
	src: url('../../fonts/Inter-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-ExtraLightItalic.woff2') format('woff2'),
	url('../../fonts/Inter-ExtraLightItalic.woff') format('woff'),
	url('../../fonts/Inter-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-MediumItalic.eot');
	src: url('../../fonts/Inter-MediumItalic.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-MediumItalic.woff2') format('woff2'),
	url('../../fonts/Inter-MediumItalic.woff') format('woff'),
	url('../../fonts/Inter-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-Regular.eot');
	src: url('../../fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-Regular.woff2') format('woff2'),
	url('../../fonts/Inter-Regular.woff') format('woff'),
	url('../../fonts/Inter-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-Medium.eot');
	src: url('../../fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-Medium.woff2') format('woff2'),
	url('../../fonts/Inter-Medium.woff') format('woff'),
	url('../../fonts/Inter-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-Black.eot');
	src: url('../../fonts/Inter-Black.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-Black.woff2') format('woff2'),
	url('../../fonts/Inter-Black.woff') format('woff'),
	url('../../fonts/Inter-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-ExtraLight.eot');
	src: url('../../fonts/Inter-ExtraLight.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-ExtraLight.woff2') format('woff2'),
	url('../../fonts/Inter-ExtraLight.woff') format('woff'),
	url('../../fonts/Inter-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-Bold.eot');
	src: url('../../fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-Bold.woff2') format('woff2'),
	url('../../fonts/Inter-Bold.woff') format('woff'),
	url('../../fonts/Inter-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-ExtraBold.eot');
	src: url('../../fonts/Inter-ExtraBold.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-ExtraBold.woff2') format('woff2'),
	url('../../fonts/Inter-ExtraBold.woff') format('woff'),
	url('../../fonts/Inter-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-SemiBold.eot');
	src: url('../../fonts/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-SemiBold.woff2') format('woff2'),
	url('../../fonts/Inter-SemiBold.woff') format('woff'),
	url('../../fonts/Inter-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-ThinItalic.eot');
	src: url('../../fonts/Inter-ThinItalic.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-ThinItalic.woff2') format('woff2'),
	url('../../fonts/Inter-ThinItalic.woff') format('woff'),
	url('../../fonts/Inter-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-SemiBoldItalic.eot');
	src: url('../../fonts/Inter-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-SemiBoldItalic.woff2') format('woff2'),
	url('../../fonts/Inter-SemiBoldItalic.woff') format('woff'),
	url('../../fonts/Inter-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-ExtraBoldItalic.eot');
	src: url('../../fonts/Inter-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-ExtraBoldItalic.woff2') format('woff2'),
	url('../../fonts/Inter-ExtraBoldItalic.woff') format('woff'),
	url('../../fonts/Inter-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-BoldItalic.eot');
	src: url('../../fonts/Inter-BoldItalic.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-BoldItalic.woff2') format('woff2'),
	url('../../fonts/Inter-BoldItalic.woff') format('woff'),
	url('../../fonts/Inter-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-BlackItalic.eot');
	src: url('../../fonts/Inter-BlackItalic.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-BlackItalic.woff2') format('woff2'),
	url('../../fonts/Inter-BlackItalic.woff') format('woff'),
	url('../../fonts/Inter-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-Thin.eot');
	src: url('../../fonts/Inter-Thin.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-Thin.woff2') format('woff2'),
	url('../../fonts/Inter-Thin.woff') format('woff'),
	url('../../fonts/Inter-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-LightItalic.eot');
	src: url('../../fonts/Inter-LightItalic.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-LightItalic.woff2') format('woff2'),
	url('../../fonts/Inter-LightItalic.woff') format('woff'),
	url('../../fonts/Inter-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
	//font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../../fonts/Inter-Italic.eot');
	src: url('../../fonts/Inter-Italic.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/Inter-Italic.woff2') format('woff2'),
	url('../../fonts/Inter-Italic.woff') format('woff'),
	url('../../fonts/Inter-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	//font-display: swap;
}
