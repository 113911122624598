$font-primary: 'Inter', sans-serif;
$statuses-new: #00D081;
$border-color: #DDE1E8;
$color-dark: #1B1D1D;
$color-grey: #7E8787;
$color-light: #A9B6B6;
$color: #000;
$color-bgr: #f3f3f3;
$color-bgr-disabled: rgba(#1B1D1D, .1);
$color-blue: #0E4DA4;
$color-disabled: #B1B4B4;
$color-error: #EB2A50;
$color-red: #f94341;
$color-green: #0EA441;

$basic: #0EA441;
$standard: #0E4DA4;
$premium: #EE9A0C;

$link-font-color: $color-blue;

$breakpoints: (
		xs: 640px,
		sm: 1024px,
		md: 1440px,
		lg: 1440px,
		xl: 1880px,
) !default;

@mixin media-breakpoint-down($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (max-width: map-get($breakpoints, $breakpoint) - .02) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}

@mixin media-breakpoint-up($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (min-width: map-get($breakpoints, $breakpoint)) {
			@content;
		}
	} @else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}


@function breakpoint-next($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $breakpoints) {
	$next: breakpoint-next($name, $breakpoints);
	@return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

@mixin media-breakpoint-only($name, $breakpoints: $breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$max: breakpoint-max($name, $breakpoints);

	@if $min != null and $max != null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max == null {
		@include media-breakpoint-up($name, $breakpoints) {
			@content;
		}
	} @else if $min == null {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}

.PaymentConfirmation {
	&__container {
		width: 832px;
		max-width: 100%;
		margin: 0 auto;
		background: #FFFFFF;
		box-shadow: 0px 4px 30px rgba(122, 125, 137, 0.05);
		border-radius: 2px;
		padding: 70px 16px 100px;
		min-height: 496px;

		@media (max-width: 640px) {
			min-height: 0;
		}
	}

	&__icon {
		display: block;
		max-width: 100%;
		height: auto;

		&-container {
			min-height: 96px;
			display: flex;
			align-items: center;
			justify-content: center;
			align-content: center;
			margin: 0 auto 20px;
		}
	}

	&__header {
		font-weight: 600;
		font-size: 28px;
		line-height: 34px;
		text-align: center;
		margin: 0 0 32px;

		@media (max-width: 640px) {
			font-size: 22px;
			line-height: 26px;
			margin: 0 0 28px;
		}

		&.error {
			color: $color-error;
		}

		&.progress {
			color: $color-blue;
		}

		&.success {
			color: $color-green;
		}
	}

	&__copy {
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		color: $color-dark;
		margin: 0 0 46px;

		@media (max-width: 640px) {
			font-size: 12px;
			line-height: 15px;
			margin: 0 0 30px;
		}
	}

	&__center {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}

	&__btn {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		background: rgba($color-blue, 0.1);
		border-radius: 4px;
		text-align: center;
		min-width: 160px;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: $color-blue;
		transition: color .2s, background-color .2s;
		height: 48px;

		&:hover {
			color: #fff;
			background: $color-blue;
		}
	}
}
